import React, { useRef } from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const PrivacyNoticePage = () => {
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 25)
  const information = useRef(null)
  const collect = useRef(null)
  const use = useRef(null)
  const legal = useRef(null)
  const marketing = useRef(null)
  const share = useRef(null)
  const thirdParty = useRef(null)
  const right = useRef(null)
  const externalWebsites = useRef(null)
  const protect = useRef(null)
  const international = useRef(null)
  const retention = useRef(null)
  const child = useRef(null)
  const changes = useRef(null)
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <section className="container-left container-right privacy">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Last revised July, 2020</strong>
        </p>
        <p>
          Ceuta Healthcare Limited (“Ceuta,” “we,” “us,” and “our”) has been
          appointed by Blistex Inc. (“Blistex”) to act as its agent in the UK,
          to market Blistex’s range of products (“Products”) and to answer and
          investigate any queries and complaints regarding the Products within
          the United Kingdom.
        </p>
        <p>
          We understand that privacy is important to you (“you” and “your”) as
          the user of the Products. This privacy policy (“Policy”) sets out the
          basis on which any personal information we collect from you, or that
          you provide to us, through{" "}
          <a href="https://www.blistex.co.uk">http://www.blistex.co.uk</a> (the
          “Site”), which is owned by Blistex and operated by Ceuta on Blistex’s
          behalf.
        </p>
        <p>
          It is important that you read this Policy together with any other
          privacy policy or fair processing policy we may provide on specific
          occasions when we are collecting or processing personal information
          about you so that you are fully aware of how and why we are using your
          data. By using the Site, you agree to the collection, use and sharing
          of your personal information as set forth in this Policy.
        </p>
        <h2>Contact Details and Controller</h2>
        <p>
          If you want to contact us in relation to your personal information,
          please contact us at{" "}
          <a href="mailto:dataprotection@ceutagroup.com">
            dataprotection@ceutagroup.com
          </a>{" "}
          or write us at Ceuta Healthcare, FAO Data Protection, Hill House, 41
          Richmond Hill, Bournemouth, Dorset, BH2 6HS
        </p>
        <p>
          For the purposes of the General Data Protection Regulation (“GDPR”),
          the data controller of your personal information collected through the
          Site is: Ceuta Healthcare Limited, Hill House, 41 Richmond Hill,
          Bournemouth, Dorset, BH2 6HS.
        </p>
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(information)}
              style={{ cursor: "pointer" }}
            >
              What Information We Collect
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(collect)}
              style={{ cursor: "pointer" }}
            >
              How We Collect Information
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(use)}
              style={{ cursor: "pointer" }}
            >
              How We Use Your Information
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(legal)}
              style={{ cursor: "pointer" }}
            >
              Legal Bases for Processing Your Data
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(marketing)}
              style={{ cursor: "pointer" }}
            >
              Marketing
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(share)}
              style={{ cursor: "pointer" }}
            >
              How We Share Your Information
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(thirdParty)}
              style={{ cursor: "pointer" }}
            >
              Information About Third-Party Cookies and Interest-Based
              Advertising
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(right)}
              style={{ cursor: "pointer" }}
            >
              Your Rights
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(externalWebsites)}
              style={{ cursor: "pointer" }}
            >
              Links to Other Websites
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(protect)}
              style={{ cursor: "pointer" }}
            >
              How We Protect Your Information
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(international)}
              style={{ cursor: "pointer" }}
            >
              International Transfers
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(retention)}
              style={{ cursor: "pointer" }}
            >
              Retention of Personal Information
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(child)}
              style={{ cursor: "pointer" }}
            >
              Child Privacy
            </a>
          </li>
          <li>
            <a
              role="button"
              onClick={() => scrollToRef(changes)}
              style={{ cursor: "pointer" }}
            >
              Changes To Our Privacy Policy
            </a>
          </li>
        </ul>
        <h2 ref={information}>What Information We Collect</h2>
        <p>
          We do not collect personal information about you unless you
          voluntarily submit that information to us via the Site or by
          contacting us by phone or mail. The personal information which you may
          provide to us could include:
        </p>
        <ul>
          <li>
            Your name and, if different, the name of the user of the Products
          </li>
          <li>
            Your contact information including your email address, postal
            address and telephone number
          </li>
          <li>Your age and gender</li>
          <li>
            Details of any adverse event, enquiry or complaint relating to the
            Products
          </li>
          <li>
            Communications data, for example any information which you choose to
            provide to us when you communicate with us or receive customer
            support
          </li>
          <li>
            Device and technical data, such as your browser type, operating
            system, and your IP address (a unique address that identifies your
            computer on the Internet)
          </li>
          <li>
            Marketing data, including your preferences in receiving marketing
            from us and our any relevant third parties to which you have
            expressly consented, and your engagement with our marketing
            communications
          </li>
          <li>
            Details of the Products used and any other products used at the same
            time
          </li>
        </ul>

        <h2 ref={collect}>How We Collect Information</h2>
        <p>We collect personal information:</p>
        <ul>
          <li>
            directly from you (for example, when you contact us via the Site or
            when you provide information to us either by phone, email or mail)
          </li>
          <li>
            through automated technologies and interactions, e.g. via cookies
            (for example, as you interact with and use our Site)
          </li>
          <li>
            from third party marketing analytics service providers, website
            analysis firms, or search information providers, such as data
            aggregators
          </li>
        </ul>
        <h2 ref={use}>How We Use Your Information</h2>
        <p>
          We use the information we collect through the Site in the following
          ways:
        </p>
        <ul>
          <li>
            to provide the information, products and services you request;
          </li>
          <li>
            to efficiently respond to your questions, enquiries or complaints;
          </li>
          <li>to provide you with effective customer service;</li>
          <li>
            to provide you with a personalized experience when you use the Site;
          </li>
          <li>
            to contact you with information and notices related to your use of
            the Products and the Site;
          </li>
          <li>
            to improve the content, functionality and usability of the Site;
          </li>
          <li>to better understand your needs and interests;</li>
          <li>
            to represent Blistex in relation to the Products in the UK and allow
            Blistex to improve the Products;
          </li>
          <li>to improve our marketing and promotional efforts;</li>
          <li>to share with others as described in this Policy;</li>
          <li>in accordance with your consent; and</li>
          <li>
            for any other purpose identified in an applicable Privacy Notice,
            click-through agreement or other agreement between you and us.
          </li>
        </ul>
        <h2 ref={legal}>Legal Bases for Processing Your Data</h2>
        <p>
          We only use your personal information when the law allows us to. Most
          commonly, we use your personal information in the following
          circumstances:
        </p>
        <ul>
          <li>
            Where it is necessary for our legitimate interests (or those of a
            third party (for example) Blistex)) and your interests and
            fundamental rights do not override those interests, which include
            growing our business, efficiently dealing with customer complaints
            and enquiries, efficiently fulfilling our legal and contractual
            duties and managing legal risk, providing high-quality customer
            service, and keeping you informed (including marketing).
          </li>
          <li>
            Where we need to comply with a legal obligation, such as to record
            all safety or quality issues for monitoring purposes of the Products
            and to report safety and quality issues relating to the Products to
            the relevant regulator or authority.
          </li>
          <li>Where you have provided your consent (see “Marketing” below).</li>
        </ul>
        <h2 ref={marketing}>Marketing</h2>
        <p>
          In relation to marketing communications, we will provide you with an
          “opt in” or “opt out” mechanism depending on where you are located
          when we collect your personal information. An “opt in” mechanism will
          provide you the opportunity to positively indicate that you would like
          to receive our marketing communications and we will not send you any
          unless you have “opted in”. An “opt out” mechanism (e.g.,
          “unsubscribe”) will provide you the opportunity to indicate that you
          do not want us to send you such communications, and if you “opt out”
          we will not send you any.
        </p>
        <p>
          You may opt out of marketing-related emails by clicking on a link at
          the bottom of each such email, or by contacting us using the contact
          details above. You may continue to receive service-related and other
          non-marketing emails. We will get your express opt-in consent before
          we share your personal information with any third party for marketing
          purposes.
        </p>
        <h2 ref={share}>How We Share Your Information</h2>
        <p style={{ textDecoration: "underline" }}>With Blistex</p>
        <p>
          Ceuta shares your personal information with Blistex and its
          subsidiaries, to enable Blistex to efficiently deal with your
          enquiries, question or complains and to effectively manage the
          marketing and sale of the Products in the UK market, e.g. to manage
          product recalls.
        </p>
        <p style={{ textDecoration: "underline" }}>With Third-Party Vendors</p>
        <p>
          Ceuta shares information collected through the Site with third-party
          vendors who act for us or on our behalf. For example, we may use
          third-party service providers (“Service Providers”) to design and
          operate the Site, or to develop software applications and databases;
          to conduct surveys; and to help us with our promotional efforts. These
          Service Providers may need may have access to your personal and
          anonymous information in order to perform these functions on our
          behalf. These Service Providers may also contact you or send
          communications on our behalf, including, without limitation, in
          connection with market research studies about our products or our
          competitors’ products.
        </p>
        <p style={{ textDecoration: "underline" }}>
          As Part of a Business Transfer
        </p>
        <p>
          Your information may be transferred to a successor organization if,
          for example, we transfer the ownership or operation of the Site to
          another organization or if we merge with another organization. If such
          a transfer occurs, the successor organization’s use of your
          information will still be subject to this Policy and the privacy
          preferences you have expressed to us.
        </p>
        <p style={{ textDecoration: "underline" }}>
          To Comply with Laws and Protect Our Rights and the Rights of Others
        </p>
        <p>
          We may disclose your information when we, in good faith, believe
          disclosure is appropriate to comply with the law or a court order. We
          may also disclose your information to prevent or investigate a
          possible crime, such as fraud or identity theft; to protect the
          security of the Site; to enforce or apply our Terms of Use or other
          agreements; or to protect our own rights or property or the rights,
          property or safety of our users or others. We also reserve the right
          to disclose your information as described in any click-through
          agreement to which you have agreed.
        </p>
        <h2 ref={thirdParty}>
          Information About Third-Party Cookies and Interest-Based Advertising
        </h2>
        <p>
          On our Site, we collect information using “cookies.” Cookies are small
          data files stored on your device. We may use both session cookies
          (which expire once you close your web browser) and persistent cookies
          (which stay on your device until you delete them) to provide you with
          a more personal and interactive experience on our Site. We only use
          first-party cookies, served directly by us to your device, which we
          use to recognize your device when it revisits our Site.
        </p>
        <p>
          You can find more information about cookies and how to manage them at{" "}
          <a href="www.allaboutcookies.org" target="_blank">
            www.allaboutcookies.org
          </a>
          .
        </p>
        <table>
          <thead>
            <tr>
              <th
                style={{
                  width: "30%",
                  paddingRight: "15px",
                  paddingBottom: "20px",
                  textAlign: "left",
                }}
              >
                <strong>Type of Cookie</strong>
              </th>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                <strong>Purpose</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  fontStyle: "italics",
                  width: "30%",
                  paddingRight: "15px",
                  paddingBottom: "10px",
                }}
              >
                Essential Cookies
              </td>
              <td style={{ paddingBottom: "10px" }}>
                These cookies are essential to provide you with services
                available through our Site and to enable you to use some of
                their features. Without these cookies, the services that you
                request via our Services may not be possible to provide. We only
                use these cookies to provide you with those services.
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontStyle: "italics",
                  width: "30%",
                  paddingRight: "15px",
                  paddingBottom: "10px",
                }}
              >
                Functionality Cookies
              </td>
              <td style={{ paddingBottom: "10px" }}>
                These cookies allow our Site to remember choices you make when
                you use our Site. The purpose of these cookies is to provide you
                with a more personalized experience and to avoid you from having
                to re-select your preferences every time you use our Site.
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontStyle: "italics",
                  width: "30%",
                  paddingRight: "15px",
                  paddingBottom: "10px",
                }}
              >
                Analytics and Performance Cookies
              </td>
              <td style={{ paddingBottom: "10px" }}>
                These cookies are used to collect information about traffic to
                our Site and how users use our Site. The information gathered
                may include the number of visitors to our Site, the websites
                that referred them to our Site, the pages they visited on our
                Site, what time of day they visited our Site, whether they have
                visited our Site before, and other similar information. We use
                this information to help operate our Site more efficiently, to
                gather demographic information and to monitor the level of
                activity on our Services.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          You can typically remove or reject cookies via your browser settings.
          To do this, follow the instructions provided in your browser (usually
          located within the “settings,” “help,” “tools” or “edit” facility).
          Many browsers are set to accept cookies until you change your
          settings.
        </p>
        <p>
          If you do not accept our cookies, you may experience some
          inconvenience in your use of our Site. For example, we may not be able
          to recognize your device.
        </p>

        <h2 ref={right}>Your Rights</h2>
        <p>
          Under certain circumstances, you have the following rights in relation
          to the personal information we hold about you, unless provided
          otherwise by relevant local legislation:
        </p>
        <ul>
          <li>Request access to your personal information</li>
          <li>Request correction of your personal information</li>
          <li>Request erasure of your personal information</li>
          <li>Object to processing of your personal information</li>
          <li>Request restriction of processing your personal information</li>
          <li>Request transfer of your personal information</li>
          <li>
            Right to withdraw consent (where we rely on your consent as our
            basis for processing)
          </li>
        </ul>
        <p>
          You can exercise any of these rights by contacting us using the
          contact details provided above.
        </p>
        <p>
          Please note that some of these rights are not absolute and we may
          refuse a request to exercise particular rights, but we will keep you
          informed as to the actions that we can take when you make your
          request.
        </p>
        <p>
          You also have the right to lodge a complaint with the Information
          Commissioner’s Office. Further information, including contact details,
          is available at{" "}
          <a href="https://ico.org.uk" target="_blank">
            https://ico.org.uk
          </a>
          .
        </p>
        <p>
          You will not have to pay a fee to access your personal information (or
          to exercise any of the other rights). However, we may charge a
          reasonable fee if your request is clearly unfounded, repetitive or
          excessive. We may need to request specific information from you to
          help us confirm your identity and ensure your right to access your
          personal information (or to exercise any of your other rights).
          Alternatively, we could refuse to comply with your request in these
          circumstances. We try to respond to all legitimate requests within one
          month.
        </p>
        <h2 ref={externalWebsites}>Links to Other Websites</h2>
        <p>
          Our Site may link to third-party websites or advertisers. These
          third-party websites or advertisers may collect certain personal and
          non-personal information, including, product usage data, and general
          location based information. These third-party sites or advertisers may
          share information they collect about you with us but we are not
          responsible for their data collection practices.
        </p>
        <p>
          We are not responsible for the privacy practices of other websites. We
          encourage users to read the privacy statements of other websites that
          collect personal information. This Policy applies only to information
          collected by Ceuta via the Site.
        </p>
        <h2 ref={protect}>How We Protect Your Information</h2>
        <p>
          We have put in place appropriate security measures to prevent your
          personal information from being accidentally lost, used or accessed in
          an unauthorised way, altered or disclosed. In addition, we limit
          access to your personal data to those employees, agents, contractors
          and other third parties who have a business need to know. They will
          only process your personal data on our instructions and they are
          subject to a duty of confidentiality. All information you provide to
          us is stored on our secure servers.
        </p>
        <h2 ref={international}>International Transfers</h2>
        <p>
          Where our service providers or other third parties (identified above)
          based outside the European Economic Area receive personal information,
          we ensure the transfer is either (a) to a country deemed to provide an
          adequate level of protection by the European Commission, (b) pursuant
          to specific contracts approved by the European Commission, or (c) for
          transfers to the US, pursuant to that third party’s certification to
          the Privacy Shield. Please contact us if you want further information
          on the specific mechanism used by us when transferring your personal
          information out of the EEA or Switzerland.
        </p>

        <p>
          Blistex is based in the United States and so information may be
          transferred to and accessed by Blistex personnel in the United States.
          By using the Site, you consent to the processing, transfer and storage
          of your information in and to the United States and other countries,
          where you may not have the same rights as you do under your local law.
        </p>
        <h2 ref={retention}>Retention of Personal Information</h2>
        <p>
          We only retain your personal information for as long as necessary to
          fulfil the purposes for which it was collected, including for the
          purposes of satisfying legal, accounting, or reporting requirements.
          This may mean that for product safety notifications, we may have to
          retain your personal information indefinitely, but most other personal
          information is kept for no longer than 6 years.
        </p>
        <p>
          In some circumstances we may anonymize your personal information (so
          that it can no longer be associated with you) in which case we may use
          this information indefinitely without further notice to you.
        </p>
        <h2 ref={child}>Child Privacy</h2>
        <p>
          We do not knowingly collect personal information from anyone under the
          age of 16. If you are a parent or guardian and become aware that your
          child has provided us with personal information without your consent,
          please contact us using the contact details above.
        </p>
        <h2 ref={changes}>Changes To Our Privacy Policy</h2>
        <p>
          We may update this Policy to reflect changes to our information
          practices. If we do this and the changes are material, we will post a
          notice that we have made changes to this Policy on Site for a
          reasonable period prior to implementing the changes, and we will
          indicate the date these terms were last revised at the top of the
          Policy. Any revisions to this Policy will become effective on the date
          stated in the notice.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyNoticePage
